import { useDispatch, useSelector } from 'react-redux';
import cls from './Popup.module.scss';
import { setIsVisiblePopup, setPause } from '../../redux/game/slice';
import { RootState } from '../../redux/store';
import { useCallback } from 'react';
import { playButtonSound } from '../SoundComponent/SoundComponent';
import router from '../../../app/router';
import { FormattedMessage, useIntl } from 'react-intl';

const Popup = () => {
  const dispatch = useDispatch();
  const isSound = useSelector((state: RootState) => state.game.isSound);
  const { tryCount } = useSelector((state: RootState) => state.game);
  const intl = useIntl();

  const speechArr = [
    intl.formatMessage({ id: 'gameover__text-1' }),
    intl.formatMessage({ id: 'gameover__text-2' }),
    intl.formatMessage({ id: 'gameover__text-3' }),
    intl.formatMessage({ id: 'gameover__text-4' }),
    intl.formatMessage({ id: 'gameover__text-5' }),
    intl.formatMessage({ id: 'gameover__text-6' }),
    intl.formatMessage({ id: 'gameover__text-7' }),
    intl.formatMessage({ id: 'gameover__text-8' }),
    intl.formatMessage({ id: 'gameover__text-9' }),
  ];

  const randomNumber = Math.floor(Math.random() * speechArr.length);
  const randomSpeech = useCallback(() => speechArr[randomNumber], [tryCount]);

  return (
    <div className={cls.PopopContainer}>
      <p>{randomSpeech()}</p>
      <div className={cls.buttonContainer}>
        {tryCount < 2 && (
          <button
            className={cls.firstButton}
            onClick={() => {
              playButtonSound(isSound);
              router.navigate('/game');
              dispatch(setPause(false));
              dispatch(setIsVisiblePopup(false));
            }}
          >
            <FormattedMessage id="gameover__btn__play" />
          </button>
        )}
        <button
          onClick={() => {
            playButtonSound(isSound);
            router.navigate('/final');
            dispatch(setPause(false));
            dispatch(setIsVisiblePopup(false));
          }}
        >
          <FormattedMessage id="gameover__btn__change-hero" />
        </button>
      </div>
    </div>
  );
};

export default Popup;
