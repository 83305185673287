import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../shared/redux/store';
import bgMusic from '../shared/assets/sound/music.aac';
import { useEffect } from 'react';
import { isLoad } from '../shared/ui/Api/getFunc';
import router from './router';

import './styles/index.scss';
import { setSound } from '../shared/redux/game/slice';

const soundBg = new Audio(bgMusic);
soundBg.loop = true;

function App() {
  const { isSound } = useSelector((state: RootState) => state.game);

  document.addEventListener('visibilitychange', () => {
    console.log(isSound);
    if (isSound && document.visibilityState === 'visible') {
      soundBg.play();
    } else {
      soundBg.pause();
    }
  });

  useEffect(() => {
    console.log('123', isSound);
    if (isSound) {
      soundBg.play();
    } else {
      soundBg.pause();
    }
  }, [isSound]);

  useEffect(() => {
    localStorage.setItem('isFirst', 'true');

    return () => {
      soundBg.pause();
    };
  }, []);

  window.addEventListener('load', isLoad);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
