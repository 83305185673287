import ReactDOM from 'react-dom/client';
import App from './app/App';
import { Provider } from 'react-redux';
import store from './shared/redux/store';
import { initialization } from './shared/scripts';
import { playdeck } from './shared/playdeck';
import { IntlProvider } from 'react-intl';
import { languages } from './app/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initialization().then((user) => {
  console.log(user);
  window.user = user;
  // playdeck.setData('user', {}); //reset data
  // const locale = 'ru';
  const locale = user.locale === 'ru' ? 'ru' : 'en';
  const lanugage = languages[locale];

  root.render(
    <Provider store={store}>
      <IntlProvider locale={locale} messages={lanugage}>
        <App />
      </IntlProvider>
    </Provider>
  );
});
