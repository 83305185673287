import cls from './PenisMenu.module.scss';
import BallClassic from '../../shared/assets/images/penis-classic.svg';
import BallRibbed from '../../shared/assets/images/penis-ribbed.png';
import BallEye from '../../shared/assets/images/penis-eye.svg';
import BallBlack from '../../shared/assets/images/penis-black.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Ball, setBall } from '../../shared/redux/chooseSlice';
import { Link } from 'react-router-dom';
import { resetCount, setPause, setSound } from '../../shared/redux/game/slice';
import { resetObstacles } from '../../shared/redux/obstacles/slice';
import SoundComponent, {
  playButtonSound,
} from '../../shared/ui/SoundComponent/SoundComponent';
import { RootState } from '../../shared/redux/store';
import { FormattedMessage } from 'react-intl';

const BallMenu = () => {
  const dispatch = useDispatch();
  const isSound = useSelector((state: RootState) => state.game.isSound);

  const onClickBall = (item: Ball) => {
    dispatch(setBall(item));
    playButtonSound(isSound);
    dispatch(resetCount());
    dispatch(resetObstacles());
    dispatch(setPause(false));
  };
  return (
    <div className={cls.container}>
      <h2>
        <FormattedMessage id="penis-menu__text" values={{ br: <br /> }} />
      </h2>
      <div className={cls.items}>
        <Link to="/game">
          <div
            className={cls.BallItem}
            onClick={() => onClickBall(Ball.ClASSIC)}
          >
            <img src={BallClassic} alt="Classic Ball" aria-disabled />
          </div>
        </Link>
        <Link to="/game">
          <div
            className={cls.BallItem}
            onClick={() => onClickBall(Ball.RIBBED)}
          >
            <img src={BallRibbed} alt="Ribbed Ball" aria-disabled />
          </div>
        </Link>
        <Link to="/game">
          <div className={cls.BallItem} onClick={() => onClickBall(Ball.EYE)}>
            <img src={BallEye} alt="Ball with eye" aria-disabled />
          </div>
        </Link>
        <Link to="/game">
          <div className={cls.BallItem} onClick={() => onClickBall(Ball.BLACK)}>
            <img src={BallBlack} alt="Black Ball" aria-disabled />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BallMenu;
