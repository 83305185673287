import cls from './ButtonMenu.module.scss';
import pauseIcon from '../../assets/images/pause.svg';
import playIcon from '../../assets/images/play.svg';
import arrowIcon from '../../assets/images/strelka.svg';
import moneyIcon from '../../assets/images/money.svg';
import { useDispatch, useSelector } from 'react-redux';
import { OnbPages, setOnboardingPage } from '../../redux/onbPageSlice';
import { setPause, togglePause } from '../../redux/game/slice';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import SoundComponent, {
  playButtonSound,
} from '../SoundComponent/SoundComponent';
import router from '../../../app/router';

const ButtonMenu = ({ firstClick }: { firstClick: boolean }) => {
  const dispatch = useDispatch();
  const [isCoarse, setIsCoarse] = useState(false);
  const isSound = useSelector((state: RootState) => state.game.isSound);
  const { pause, count, isVisiblePopup } = useSelector(
    (state: RootState) => state.game
  );

  useEffect(() => {
    if (window.matchMedia('(pointer: coarse)').matches) {
      setIsCoarse(true);
    }
  }, []);

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.add('active');
  };

  const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.remove('active');
  };

  return (
    <div className={cls.menuContainer}>
      <div className={cls.buttonContainer}>
        <div
          className={`${cls.button} ${isCoarse ? '' : cls.hoverable}`}
          onTouchStart={isCoarse ? handleTouchStart : undefined}
          onTouchEnd={isCoarse ? handleTouchEnd : undefined}
          onClick={() => {
            if (!isVisiblePopup) {
              playButtonSound(isSound);
              dispatch(setPause(true));
              dispatch(setOnboardingPage(OnbPages.FlAPPY));
              router.navigate('/');
            }
          }}
        >
          <img alt="Come back" src={arrowIcon} className={cls.arrowIcon} />
        </div>
        <SoundComponent />
        <div
          className={`${cls.button} ${isCoarse ? '' : cls.hoverable} ${
            isVisiblePopup && cls.disabledButton
          }`}
          onTouchStart={isCoarse ? handleTouchStart : undefined}
          onTouchEnd={isCoarse ? handleTouchEnd : undefined}
          onClick={() => {
            if (!isVisiblePopup && !firstClick) {
              dispatch(togglePause());
              playButtonSound(isSound);
            }
          }}
        >
          {pause ? (
            <img alt="Play" src={playIcon} className={cls.playIcon} />
          ) : (
            <img alt="Pause" src={pauseIcon} className={cls.pauseIcon} />
          )}
        </div>
      </div>
      <div className={cls.counter}>
        <img alt="Money" src={moneyIcon} className={cls.moneyIcon} />
        <div>{count}</div>
      </div>
    </div>
  );
};

export default ButtonMenu;
