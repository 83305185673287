import { parent } from '.';

export const sendAge = (timestamp: number) => {
  const event = {
    type: 'rules_accepted',
    event_properties: {
      name: 'rules_accepted',
      birthday: timestamp,
    },
  };

  parent.postMessage(
    { playdeck: { method: 'sendAnalytics', value: event } },
    '*'
  );
};
