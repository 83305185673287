import { useDispatch, useSelector } from 'react-redux';
import AgeContainer from '../../widgets/AgeContainer/AgeContainer';
import FlappyOnboarding from '../../widgets/FlappyOnboarding/FlappyOnboarding';
import PenisMenu from '../../widgets/PenisMenu/PenisMenu';
import cls from './Onboarding.module.scss';
import { RootState } from '../../shared/redux/store';
import { OnbPages, setOnboardingPage } from '../../shared/redux/onbPageSlice';
import { useEffect } from 'react';
import { setSound, setTryCount } from '../../shared/redux/game/slice';
import { playdeck } from '../../shared/playdeck';

const Onboarding = () => {
  const currPage = useSelector((state: RootState) => state.onbPage.currentPage);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTryCount(0));
    if (window?.user?.data?.birthday) {
      dispatch(setOnboardingPage(OnbPages.FlAPPY));
    }
    playdeck.setLoading(100);
  }, []);
  const setSoundTrue = () => {
    if (currPage === OnbPages.MENU) {
      if (localStorage.getItem('isFirst') === 'true') dispatch(setSound(true));
    }
  };
  return (
    <>
      <div className={cls.Onboarding} onClick={setSoundTrue}>
        {currPage === OnbPages.AGE && <AgeContainer />}
        {currPage === OnbPages.FlAPPY && <FlappyOnboarding />}
        {currPage === OnbPages.MENU && <PenisMenu />}
      </div>
    </>
  );
};

export default Onboarding;
