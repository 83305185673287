import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { playdeck } from '../../playdeck';

interface stateType {
  pause: boolean;
  count: number;
  isVisiblePopup: boolean;
  isSound: boolean;
  tryCount: number;
}

const initialState: stateType = {
  pause: false,
  count: 0,
  isVisiblePopup: false,
  isSound: false,
  tryCount: 0,
};

export const game = createSlice({
  name: 'game',
  initialState,
  reducers: {
    togglePause(state) {
      state.pause = !state.pause;
    },
    setPause(state, action: PayloadAction<boolean>) {
      state.pause = action.payload;
    },
    incrementCount(state) {
      state.count = state.count + 1;
    },
    resetCount(state) {
      state.count = 0;
    },
    setIsVisiblePopup(state, action: PayloadAction<boolean>) {
      state.isVisiblePopup = action.payload;
    },
    toggleSound(state) {
      state.isSound = !state.isSound;
    },
    setSound(state, action: PayloadAction<boolean>) {
      state.isSound = action.payload;
    },
    setTryCount(state, action: PayloadAction<number>) {
      state.tryCount = action.payload;
    },
  },
});

export const {
  togglePause,
  incrementCount,
  setIsVisiblePopup,
  resetCount,
  toggleSound,
  setPause,
  setTryCount,
  setSound,
} = game.actions;

export default game.reducer;
