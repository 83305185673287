import { getDateByString } from './get-date-by-string';

export const isValidDate = (dateString: string): boolean => {
  if (!/^\d\d\.\d\d\.\d\d\d\d$/.test(dateString)) {
    return false;
  }
  let [dd, mm, yyyy] = dateString.split('.').map((p) => parseInt(p));

  mm -= 1;

  const date = getDateByString(dateString);
  const currentDate = new Date();

  if (date > currentDate) return false;

  return (
    date.getMonth() === mm &&
    date.getDate() === dd &&
    date.getFullYear() === yyyy
  );
};