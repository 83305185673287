import { ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { OnbPages, setOnboardingPage } from '../../shared/redux/onbPageSlice';
import BgCensor from '../../shared/assets/images/bg-censor.svg';
import YesIcon from '../../shared/assets/images/yes.svg';
import NoIcon from '../../shared/assets/images/no.svg';
import { playButtonSound } from '../../shared/ui/SoundComponent/SoundComponent';
import { getDateByString, getTextId, isAdult, isValidDate } from './utils';
import { ErrorText, inputMode } from './types';

import cls from './AgeContainer.module.scss';
import { playdeck } from '../../shared/playdeck';
import { RootState } from '../../shared/redux/store';
import { FormattedMessage, useIntl } from 'react-intl';

const AgeContainer = () => {
  const isSound = useSelector((state: RootState) => state.game.isSound);
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>('');
  const [inputMode, setInputMode] = useState<inputMode>('numeric');
  const ref = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<ErrorText>(ErrorText.NONE);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const intl = useIntl();

  const confirm = () => {
    if (testInput(value)) {
      if (!date) return;
      dispatch(setOnboardingPage(OnbPages.FlAPPY));
      playButtonSound(isSound);
      playdeck.setData('user', { birthday: date });
      playdeck.sendAge(date?.getTime());
    }
  };

  const changeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setError(ErrorText.NONE);
    let newValue = e.target.value;
    const lastChar = newValue.charAt(newValue.length - 1);
    const regex = /^[0-9.\s]*$/;
    const posFirstDot = 3;
    const posSecDot = 6;
    const length = newValue.length;

    if (!regex.test(newValue)) return;

    if (length === posFirstDot && lastChar !== '.') {
      newValue = newValue.slice(0, posFirstDot - 1) + '.' + lastChar;
    }

    if (length === posSecDot && lastChar !== '.') {
      newValue = newValue.slice(0, posSecDot - 1) + '.' + lastChar;
    }
    if (length === 2 && lastChar === '.') {
      newValue = '0' + newValue;
    }
    if (length === 5 && lastChar === '.') {
      newValue = newValue.slice(0, 3) + '0' + newValue.slice(3, 5);
    }

    setValue(newValue);
    if (length === 10) {
      testInput(newValue);
    }
  };

  const testInput = (dateString: string): boolean => {
    hideKeyboard();
    if (!isValidDate(dateString)) {
      setError(ErrorText.INCORRECT);
      setDate(undefined);

      return false;
    } else if (!isAdult(dateString)) {
      setError(ErrorText.EARLY);

      return false;
    }

    const newDate = getDateByString(dateString);
    setDate(newDate);

    return true;
  };

  const hideKeyboard = () => {
    ref.current?.setAttribute('readonly', 'true');

    setTimeout(() => {
      ref.current?.removeAttribute('readonly');
    }, 500);
  };

  return (
    <div className={cls.container}>
      <h2>
        <FormattedMessage id="register__title" values={{ br: <br /> }} />
      </h2>
      <div className={cls.censor}>
        <img alt="bg" src={BgCensor} />
        <p className={cls.content}>
          18 <span className={cls.pluse}>+</span>
        </p>
      </div>
      <p className={cls.text}>
        <FormattedMessage id="register__text" />
      </p>
      <input
        ref={ref}
        type="text"
        value={value}
        inputMode={inputMode}
        className={cn(cls.input, error !== ErrorText.NONE && cls.error)}
        required
        placeholder={intl.formatMessage({ id: 'register__input-placeholder' })}
        maxLength={10}
        onChange={changeInput}
        onBlur={() => (value.length < 10 ? testInput(value) : '')}
      />

      <div className={cls['error-desc']}>
        <p>
          <FormattedMessage id={getTextId('desc', error)} />
        </p>
      </div>

      <button
        className={cn(
          cls.button,
          !date && error === ErrorText.NONE && cls.hide
        )}
        disabled={error !== ErrorText.NONE || value.length === 0}
        onClick={confirm}
      >
        <p>
          <FormattedMessage id={getTextId('button', error)} />
        </p>
        <img
          alt="state-button"
          src={error === ErrorText.NONE ? YesIcon : NoIcon}
        />
      </button>
    </div>
  );
};

export default AgeContainer;
