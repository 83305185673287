import { ErrorText } from '../types';

export const getTextId = (
  type: 'button' | 'desc',
  error: ErrorText
): string => {
  if (type === 'button') {
    if (error === ErrorText.EARLY) {
      return 'register__error-btn-early';
    }
    if (error === ErrorText.INCORRECT) {
      return 'register__error-btn-incorrect';
    }

    return 'register__btn-play';
  }

  if (type === 'desc') {
    if (error === ErrorText.EARLY) {
      return 'register__error-desc-early';
    }
    if (error === ErrorText.INCORRECT) {
      return 'register__error-desc-incorrect';
    }
  }
  return ' ';
};
