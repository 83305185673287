import WindowImage from '../../assets/images/obstacles/window.png';
import BossImage from '../../assets/images/obstacles/boss.png';
import PosterRuImage from '../../assets/images/obstacles/poster-ru.png';
import ColleaqueOnTheChairImage from '../../assets/images/obstacles/colleague-on-the-chair.png';
import HandWithFishImage from '../../assets/images/obstacles/hand-with-fish.png';
import ChairImage from '../../assets/images/obstacles/chair.png';
import ConditionerImage from '../../assets/images/obstacles/conditioner.png';
import EvilBossImage from '../../assets/images/obstacles/evil-boss.png';
import ColleaqueOnThePoufImage from '../../assets/images/obstacles/colleague-on-the-pouf.png';
import HandWithFolderImage from '../../assets/images/obstacles/hand-with-folder.png';
import ColleaqueWithCupImage from '../../assets/images/obstacles/colleague-with-cup.png';
import LampImage from '../../assets/images/obstacles/lamp.png';
import CoolerImage from '../../assets/images/obstacles/cooler.png';
import HandWithCupImage from '../../assets/images/obstacles/hand-with-cup.png';
import FanImage from '../../assets/images/obstacles/fan.png';
import { ObstacleType } from './types';

export const boundArr = [
  {
    id: 0,
    name: 'window',
    boundaries: [
      [
        {
          x: -2,
          y: -2,
        },
        {
          x: 145,
          y: 225,
        },
      ],
      [
        {
          x: 57,
          y: 220,
        },
        {
          x: 112,
          y: 317,
        },
      ],
      [
        {
          x: 117,
          y: 220,
        },
        {
          x: 182,
          y: 293,
        },
      ],
    ],
  },
  {
    id: 1,
    name: 'boss',
    boundaries: [
      [
        {
          x: 44,
          y: 0,
        },
        {
          x: 93,
          y: 55,
        },
      ],
      [
        {
          x: 12,
          y: 70,
        },
        {
          x: 125,
          y: 140,
        },
      ],
      [
        {
          x: 37,
          y: 140,
        },
        {
          x: 115,
          y: 310,
        },
      ],
    ],
  },
  {
    id: 2,
    name: 'poster',
    boundaries: [
      [
        {
          x: 0,
          y: 0,
        },
        {
          x: 165,
          y: 271,
        },
      ],
    ],
  },
  {
    id: 3,
    name: 'Collegue on the chair',
    boundaries: [
      [
        {
          x: 40,
          y: 10,
        },
        {
          x: 100,
          y: 77,
        },
      ],
      [
        {
          x: 0,
          y: 80,
        },
        {
          x: 136,
          y: 220,
        },
      ],
      [
        {
          x: 136,
          y: 130,
        },
        {
          x: 220,
          y: 240,
        },
      ],
      [
        {
          x: 45,
          y: 230,
        },
        {
          x: 275,
          y: 375,
        },
      ],
    ],
  },
  {
    id: 4,
    name: 'Hand with fish',
    boundaries: [
      [
        {
          x: 30,
          y: 0,
        },
        {
          x: 80,
          y: 192,
        },
      ],
      [
        {
          x: 0,
          y: 225,
        },
        {
          x: 143,
          y: 260,
        },
      ],
      [
        {
          x: 15,
          y: 260,
        },
        {
          x: 100,
          y: 272,
        },
      ],
      [
        {
          x: 15,
          y: 260,
        },
        {
          x: 100,
          y: 272,
        },
      ],
      [
        {
          x: 20,
          y: 272,
        },
        {
          x: 66,
          y: 290,
        },
      ],
    ],
  },
  {
    id: 5,
    name: 'Chair',
    boundaries: [
      [
        {
          x: 72,
          y: 0,
        },
        {
          x: 210,
          y: 125,
        },
      ],
      [
        {
          x: 0,
          y: 125,
        },
        {
          x: 200,
          y: 278,
        },
      ],
    ],
  },
  {
    id: 6,
    name: 'Conditioner',
    boundaries: [
      [
        {
          x: 0,
          y: 0,
        },
        {
          x: 304,
          y: 140,
        },
      ],
      [
        {
          x: 80,
          y: 140,
        },
        {
          x: 220,
          y: 230,
        },
      ],
    ],
  },
  {
    id: 7,
    name: 'Evil boss',
    boundaries: [
      [
        {
          x: 52,
          y: 0,
        },
        {
          x: 200,
          y: 90,
        },
      ],
      [
        {
          x: 0,
          y: 90,
        },
        {
          x: 283,
          y: 330,
        },
      ],
    ],
  },
  {
    id: 8,
    name: 'Colleque on the pouf',
    boundaries: [
      [
        {
          x: 40,
          y: 0,
        },
        {
          x: 115,
          y: 66,
        },
      ],
      [
        {
          x: 15,
          y: 66,
        },
        {
          x: 175,
          y: 110,
        },
      ],
      [
        {
          x: 0,
          y: 110,
        },
        {
          x: 210,
          y: 240,
        },
      ],
      [
        {
          x: 95,
          y: 240,
        },
        {
          x: 280,
          y: 300,
        },
      ],
    ],
  },
  {
    id: 9,
    name: 'Hand with folder',
    boundaries: [
      [
        {
          x: 50,
          y: 0,
        },
        {
          x: 110,
          y: 190,
        },
      ],
      [
        {
          x: 0,
          y: 190,
        },
        {
          x: 152,
          y: 300,
        },
      ],
    ],
  },
  {
    id: 10,
    name: 'Collegue with the cup',
    boundaries: [
      [
        {
          x: 30,
          y: 0,
        },
        {
          x: 153,
          y: 340,
        },
      ],
      [
        {
          x: 0,
          y: 70,
        },
        {
          x: 30,
          y: 125,
        },
      ],
    ],
  },
  {
    id: 11,
    name: 'Lamp',
    boundaries: [
      [
        {
          x: 80,
          y: 0,
        },
        {
          x: 195,
          y: 200,
        },
      ],
      [
        {
          x: 75,
          y: 260,
        },
        {
          x: 115,
          y: 278,
        },
      ],
      [
        {
          x: 80,
          y: 0,
        },
        {
          x: 195,
          y: 200,
        },
      ],
    ],
  },
  {
    id: 12,
    name: 'Cooler',
    boundaries: [
      [
        {
          x: 0,
          y: 0,
        },
        {
          x: 110,
          y: 300,
        },
      ],
    ],
  },
  {
    id: 13,
    name: 'Hand with cup',
    boundaries: [
      [
        {
          x: 0,
          y: 0,
        },
        {
          x: 95,
          y: 270,
        },
      ],
    ],
  },
  {
    id: 14,
    name: 'Fan',
    boundaries: [
      [
        {
          x: 0,
          y: 0,
        },
        {
          x: 150,
          y: 140,
        },
      ],
      [
        {
          x: 58,
          y: 140,
        },
        {
          x: 150,
          y: 310,
        },
      ],
    ],
  },
];

export const ObstaclesArr: ObstacleType[] = [
  {
    id: 0,
    isTop: true,
    img: WindowImage,
    width: 183,
    height: 319,
  },
  {
    id: 1,
    isTop: false,
    img: BossImage,
    width: 127,
    height: 312,
  },
  {
    id: 2,
    isTop: true,
    img: PosterRuImage,
    width: 164,
    height: 270,
  },
  {
    id: 3,
    isTop: false,
    img: ColleaqueOnTheChairImage,
    width: 228,
    height: 348,
  },
  {
    id: 4,
    isTop: true,
    img: HandWithFishImage,
    width: 244,
    height: 295,
  },
  {
    id: 5,
    isTop: false,
    img: ChairImage,
    width: 214,
    height: 280,
  },
  {
    id: 6,
    isTop: true,
    img: ConditionerImage,
    width: 305,
    height: 234,
  },
  {
    id: 7,
    isTop: false,
    img: EvilBossImage,
    width: 284,
    height: 330,
  },
  {
    id: 8,
    isTop: false,
    img: ColleaqueOnThePoufImage,
    width: 280,
    height: 300,
  },
  {
    id: 9,
    isTop: true,
    img: HandWithFolderImage,
    width: 152,
    height: 300,
  },
  {
    id: 10,
    isTop: false,
    img: ColleaqueWithCupImage,
    width: 97,
    height: 340,
  },
  {
    id: 11,
    isTop: true,
    img: LampImage,
    width: 153,
    height: 280,
  },
  {
    id: 12,
    isTop: false,
    img: CoolerImage,
    width: 112,
    height: 300,
  },
  {
    id: 13,
    isTop: true,
    img: HandWithCupImage,
    width: 97,
    height: 280,
  },
  {
    id: 14,
    isTop: false,
    img: FanImage,
    width: 150,
    height: 309,
  },
];
