import cls from './Final.module.scss';
import moneyIcon from '../../shared/assets/images/money.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/redux/store';
import { OnbPages, setOnboardingPage } from '../../shared/redux/onbPageSlice';
import { Link } from 'react-router-dom';
import { resetCount, setTryCount } from '../../shared/redux/game/slice';

import { getBestScore } from '../../shared/ui/Api/getFunc';
import { useEffect, useState } from 'react';
import { shareScore } from '../../shared/ui/Api/shareTG';
import { playButtonSound } from '../../shared/ui/SoundComponent/SoundComponent';
import router from '../../app/router';
import { FormattedMessage } from 'react-intl';

const Final = () => {
  const count = useSelector((state: RootState) => state.game.count);
  const [bestCount, setBestCount] = useState<number>(0);
  const dispatch = useDispatch();
  const isSound = useSelector((state: RootState) => state.game.isSound);

  function checkEnding(num: number) {
    let lastDigit = num % 10;
    let lastTwoDigits = num % 100;

    if (lastTwoDigits >= 10 && lastTwoDigits <= 20) {
      return 'раз';
    } else if (lastDigit === 1) {
      return 'раз';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return 'раза';
    } else {
      return 'раз';
    }
  }

  useEffect(() => {
    const fetchBestScore = async () => {
      try {
        const score = await getBestScore();
        if (score !== null) {
          setBestCount(score);
        } else {
          setBestCount(0);
        }
      } catch (error) {
        console.error('Произошла ошибка при получении счета:', error);
      }
    };
    fetchBestScore();
  }, []); // Пустой массив зависимостей, чтобы выполнить этот эффект один раз при монтировании

  return (
    <div className={cls.container}>
      <div className={cls.contentContainer}>
        <p>
          <FormattedMessage
            id="final__stats-text"
            values={{
              value: count,
              end: checkEnding(count),
            }}
          />
        </p>
        <div className={cls.counter}>
          <img alt="Money" src={moneyIcon} className={cls.moneyIcon} />
          <div>{count}</div>
        </div>
        {bestCount < count ? (
          <h1>
            <FormattedMessage id="final__new-record-text" />
          </h1>
        ) : (
          <h1>
            <FormattedMessage
              id="final__record-text"
              values={{
                value: bestCount,
                end: checkEnding(bestCount),
              }}
            />
          </h1>
        )}

        <div className={cls.buttonContainer}>
          <button
            onClick={() => {
              playButtonSound(isSound);
              dispatch(resetCount());
              dispatch(setTryCount(0));
              dispatch(setOnboardingPage(OnbPages.MENU));
              router.navigate('/');
            }}
          >
            <FormattedMessage id="final__btn__game_text" />
          </button>

          {window.innerWidth < 1000 && (
            <button onClick={() => shareScore()}>
              <FormattedMessage id="final__btn__share_text" />
            </button>
          )}
        </div>
        <p className={cls.onboard}>
          <FormattedMessage
            id="final__leaderboard-text"
            values={{
              nbsp: <>&nbsp;</>,
            }}
          />
        </p>
        <Link
          className={cls['btn-leaderboard']}
          to={'/table'}
          onClick={() => {
            playButtonSound(isSound);
          }}
        >
          <FormattedMessage id="final__btn__leaderboad-text" />
        </Link>
      </div>
    </div>
  );
};

export default Final;
