import { getDateByString } from './get-date-by-string';

export const isAdult = (birthdate: string): boolean => {
  const currentDate = new Date();
  const birthdateObj = getDateByString(birthdate);
  const age = currentDate.getFullYear() - birthdateObj.getFullYear();

  console.log(age, currentDate.getMonth() - birthdateObj.getMonth());

  if (
    age > 18 ||
    (age === 18 && currentDate.getMonth() - birthdateObj.getMonth() > 0) ||
    (age === 18 &&
      currentDate.getMonth() === birthdateObj.getMonth() &&
      currentDate.getDate() - 1 - birthdateObj.getDate() >= 0)
  ) {
    return true;
  } else {
    return false;
  }
};
