import { playdeck } from '../playdeck/index';
import { Locale, UserModel } from '../playdeck/types';

export const initialization = (): Promise<UserModel> => {
  playdeck.getUser();
  playdeck.getData('user');
  playdeck.setLoading();

  return new Promise((resolve) => {
    window.addEventListener('message', ({ data }) => {
      const pdData = data?.playdeck;
      if (!pdData) return;

      if (pdData.method === 'getUserProfile') {
        window.playdeckUser = pdData.value;
      }

      if (pdData.method === 'getData') {
        if (pdData.value.data) {
          pdData.value.data = JSON.parse(pdData.value.data);
        }
        window.playdeckData = pdData.value;
      }
    });

    const interval = setInterval(() => {
      const playdeckUser = window?.playdeckUser;
      if (!playdeckUser?.telegramId || window.playdeckData === undefined)
        return;

      const resolverUser = playdeckUser;
      resolverUser.locale = resolverLocale(playdeckUser.locale);
      (resolverUser as UserModel).data = window.playdeckData.data;

      clearInterval(interval);

      resolve(resolverUser as UserModel);
    }, 1000);
  });
};

const resolverLocale = (locale: string): Locale => {
  if (locale === 'ru') return 'ru';

  return 'en';
};
