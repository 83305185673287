import { createBrowserRouter } from 'react-router-dom';
import { Final, Game, Onboarding, PopupPage, Table } from '../pages';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Onboarding />,
  },
  {
    path: '/game',
    element: <Game />,
  },
  {
    path: '/popup',
    element: <PopupPage />,
  },
  {
    path: '/final',
    element: <Final />,
  },
  {
    path: '/table',
    element: <Table />,
  },
]);

export default router;
