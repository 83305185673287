import { useDispatch, useSelector } from 'react-redux';
import cls from './FlappyOnboarding.module.scss';
import { OnbPages, setOnboardingPage } from '../../shared/redux/onbPageSlice';
import BallImage from '../../shared/assets/images/penis-classic.svg';
import SoundComponent, {
  playButtonSound,
} from '../../shared/ui/SoundComponent/SoundComponent';
import { RootState } from '../../shared/redux/store';
import { FormattedMessage } from 'react-intl';

const FlappyOnboarding = () => {
  const isSound = useSelector((state: RootState) => state.game.isSound);
  const dispatch = useDispatch();
  return (
    <div className={cls.container}>
      <h1>
        <FormattedMessage id="game_name" />
      </h1>

      <p>
        <FormattedMessage
          id="onboarding__text"
          values={{
            br: <br />,
            nbsp: <>&nbsp;</>,
          }}
        />

        <img className={cls.dick} alt="Ball" src={BallImage} />
      </p>

      <button
        onClick={() => {
          dispatch(setOnboardingPage(OnbPages.MENU));
          playButtonSound(isSound);
        }}
      >
        <FormattedMessage id="onboarding__btn__play-text" />
      </button>
    </div>
  );
};

export default FlappyOnboarding;
