import cls from './Obstacles.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchBodyAndAddObstacle } from '../../redux/obstacles/thunk';
import PosterEnImage from '../../assets/images/obstacles/poster-en.png';
import { useIntl } from 'react-intl';

const Obstacles = () => {
  const { obstacles } = useSelector((state: RootState) => state.obstacles);
  const { count } = useSelector((state: RootState) => state.game);
  const [prevCount, setPrevCount] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const [currentIdx, setCurrentIdx] = useState(0);
  const intl = useIntl();

  function getRandomExcluding(max: number, excluded: number) {
    let randomNumber;
    do {
      randomNumber = Math.floor(Math.random() * max);
    } while (randomNumber === excluded);
    return randomNumber;
  }

  useEffect(() => {
    let localCurrentIdx = currentIdx;
    for (let i = 0; i < 200; i++) {
      const randomNextObsId = getRandomExcluding(15, localCurrentIdx);
      dispatch(fetchBodyAndAddObstacle({ idx: randomNextObsId, count: i }));
      localCurrentIdx = randomNextObsId;
    }
    setCurrentIdx(localCurrentIdx);
  }, []);

  useEffect(() => {
    if (prevCount < count) {
      console.log('add obs');
      const randomNextObsId = getRandomExcluding(15, currentIdx);
      dispatch(
        fetchBodyAndAddObstacle({
          idx: randomNextObsId,
          count: prevCount + 200,
        })
      );
    }
    setPrevCount((act) => act + 1);
  }, [count]);

  return (
    <div className={cls.obstacles}>
      {obstacles.map((obs) => {
        return (
          <img
            alt="Obstacle"
            src={
              obs.item.id === 2 && intl.locale === 'en'
                ? PosterEnImage
                : obs.item.img
            }
            style={obs.style}
            key={obs.absoluteLeft}
            loading="eager"
          />
        );
      })}
    </div>
  );
};

export default Obstacles;
