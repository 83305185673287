import { useDispatch, useSelector } from 'react-redux';
import cls from './SoundComponent.module.scss';
import { toggleSound } from '../../redux/game/slice';
import { RootState } from '../../redux/store';
import soundIcon from '../../assets/images/sound.svg';
import soundOffIcon from '../../assets/images/soundOff.svg';
import { useEffect, useState } from 'react';
import buttonSound from '../../assets/sound/button.aac';

export function playButtonSound(isSound: boolean) {
  if (isSound) {
    const clickSound = new Audio(buttonSound);
    clickSound.play();
  }
}

const SoundComponent = () => {
  const [isCoarse, setIsCoarse] = useState(false);
  const dispatch = useDispatch();
  const isSound = useSelector((state: RootState) => state.game.isSound);
  useEffect(() => {
    if (window.matchMedia('(pointer: coarse)').matches) {
      setIsCoarse(true);
    }
  }, []);
  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.add('active');
  };

  const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = (e) => {
    e.currentTarget.classList.remove('active');
  };

  
  return (
    <div
      className={`${cls.button} ${isCoarse ? '' : cls.hoverable}`}
      onTouchStart={isCoarse ? handleTouchStart : undefined}
      onTouchEnd={isCoarse ? handleTouchEnd : undefined}
      onClick={() => {
        dispatch(toggleSound());
        playButtonSound(isSound);
      }}
    >
      <img
        alt="Toggle sound"
        src={isSound ? soundIcon : soundOffIcon}
        className={cls.soundIcon}
      />
    </div>
  );
};

export default SoundComponent;
