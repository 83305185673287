import { getData } from './get-data';
import { getUser } from './get-user';
import { setLoading } from './set-loading';
import { setData } from './set-data';
import { sendAge } from './send-age';

export const parent = window.parent.window;

export const playdeck = {
  getUser: getUser,
  setLoading: setLoading,
  getData: getData,
  setData: setData,
  sendAge: sendAge,
};
